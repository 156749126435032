import { Menu } from "@headlessui/react";
import { LiveChatWidget } from "@livechat/widget-react";
import classNames from "classnames/bind";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ContactPop from "../components/contactPop";
import { Container } from "../components/utils";
import { PhrasesContext } from "../context/phrasesContext";
import { RequesterContext } from "../context/requesterContext";
import { useAuthContext } from "../providers/authContext";
// import { getRequester } from '../services/Common';

const cx = classNames;

const Nav = (props) => {
  // const { lang } = props;
  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  const [requesterInfo] = useContext(RequesterContext);
  // const isOffice = window.localStorage.getItem("office");
  // console.log("isOffice", isOffice);
  // {lang === "en"
  //             ? enPhrase["lbl_office_title"]
  //             : arPhrase["lbl_office_title"]}

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isAuthorized, HappinessHandlerClick } = useAuthContext();
  const [currentSection, setCurrentSection] = useState("home");
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const [openContactPop, setopenContactPop] = useState(false);

  const [activeColor, setActiveColor] = useState(false);

  const buttonClickHandler = () => {
    props.changeLanguage2(props.lang);
  };

  const handleClickScroll = (section) => {
    const element = document.getElementById(section);
    setCurrentSection(section);
    if (element) {
      const headerOffset = 190;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    } else {
      setTimeout(() => {
        const element = document.getElementById(section);
        const headerOffset = 150;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }, 800);
    }
    setCurrentSection("");
  };
  const [lang, setLang] = useState("");
  const [chatAdel, setChatAdel] = useState(true);
  const [showPopUp, setShowPopUp] = useState(false);
  const ChatHandler = () => {
    if (chatAdel) {
      setChatAdel(false);
    } else {
      setChatAdel(true);
    }
  };

  const popupHandler = () => {
    setShowPopUp(!showPopUp);
    if (typeof window != "undefined" && window.document && showPopUp !== true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  };

  // const HappinessHandlerClick = () => {
  //   setHappinessShowModal(true);
  // };

  useEffect(() => {
    const lang = window.localStorage.getItem("lang");
    let currentLang = lang;
    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
      currentLang = "en";
    } else {
      document.body.dir = "rtl";
      setLang("ar");
      currentLang = "ar";
    }
    // getRequesterService(currentLang);
  }, []);
  // const getRequesterService = (currentLang) => {
  //   return getRequester(currentLang).then((res) => {
  //     setRequester(res);
  //   });
  // };

  //  Stage Main Test
  const getUri = () => {
    return (
      `https://stg-id.uaepass.ae/idshub/authorize?redirect_uri=https://eje.arabiansystems.com/authentication&client_id=sandbox_stage&response_type=code&state=ShNP22hyl1jUU2RGjTRkpg==&scope=urn:uae:digitalid:profile:generalurn:uae:digitalid:profile:general:profileTypeurn:uae:digitalid:profile:general:unifiedId&acr_values=urn:safelayer:tws:policies:authentication:level:low&ui_locales=` +
      lang
    );
  };

  // LIVE
  // const getUri = () => {
  //   return (
  //     `https://id.uaepass.ae/idshub/authorize?redirect_uri=https://auth.eje.ae&client_id=ajm_eje_web_prod&response_type=code&state=PW8uDbLcMEeft9Ap&scope=urn:uae:digitalid:profile:general&acr_values=urn:safelayer:tws:policies:authentication:level:low&ui_locales=` +
  //     lang
  //   );
  // };

  const [activeClass, setActiveClass] = useState("home");
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY === 0) {
        setActiveClass("homes");
      } else if (window.scrollY > 355 && window.scrollY <= 740) {
        setActiveClass("services");
      } else if (window.scrollY > 740 && window.scrollY <= 1380) {
        setActiveClass("home");
      } else if (window.scrollY > 1380 && window.scrollY <= 1754) {
        setActiveClass("partner");
      } else if (window.scrollY > 2258 && window.scrollY <= 2845) {
        setActiveClass("news");
      } else if (window.scrollY > 2845 && window.scrollY <= 3642) {
        setActiveClass("offices");
      } else if (window.scrollY > 3642) {
        setActiveClass("faqs");
      }
    });
  }, []);

  const chatSettings = {
    language: "ar", // Set language to Arabic
  };

  return (
    <nav
      ref={props.navbarRef}
      className="text-gray-600 py-0  text-base xl:text-lg font-medium bg-white sticky top-0 xl:pt-2  w-full z-50 relative px-[24px] xl:px-[0px]"
    >
      <Container className="flex flex-col xl:py-0">
        <div className="flex items-center gap-[24px]  justify-between w-full py-3 border-b-[0px] md:border-b">
          <div className="flex items-center">
            <div className="xl:hidden ">
              <Menu as="div" className=" inline-block text-left">
                <Menu.Button
                  className="inline-flex w-full justify-center items-center rounded-md  px-2 text-sm font-medium text-gold-100  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                  onClick={popupHandler}
                >
                  {/* <HiMenu className="h-7 w-7" /> */}
                  {showPopUp ? (
                    <img src="/assets/img/home/menu.svg" alt="menu" />
                  ) : (
                    <img src="/assets/img/mobile/menu.svg" alt="menu" />
                  )}
                </Menu.Button>

                <div
                  className={`w-full md:w-[375px] bg-[#f9f7ed]  pt-[24px] top-[71px] h-auto ${
                    isAuthorized
                      ? "max-h-[700px] xsm:max-h-[680px]  overflow-y-scroll  overflow-hidden md:!overflow-visible"
                      : "max-h-[600px] xsm:max-h-[670px]  overflow-y-scroll  overflow-hidden md:!overflow-visible"
                  }  ${
                    showPopUp ? "absolute left-0 right-0 z-[1000]" : "hidden"
                  }`}
                >
                  {isAuthorized ? (
                    <div className="flex items-center justify-between px-[20px] m-auto">
                      <div className="flex flex-col items-start justify-start gap-[8px]">
                        <p className="text-[16px] text-[#212121] font-Almarai font-bold h-[22px] capitalize">
                          {requesterInfo?.name}
                        </p>
                        {requesterInfo.emiratesIdNo && (
                          <span className="text-[12px] text-[#888] font-Almarai h-[12px]">
                            {requesterInfo.emiratesIdNo}
                          </span>
                        )}
                      </div>
                      <Link
                        to={"/profile"}
                        onClick={() => {
                          document.body.style.overflow = "unset";
                          setShowPopUp(false);
                        }}
                        className="border-[1px] p-[8px] border-solid border-[#b68a35] bg-[#b68a35] w-[120px] flex items-center justify-between rounded-[8px] gap-[4px] h-[32px]"
                      >
                        <span className="  text-[12px] text-[#fff] font-Almarai ">
                          {lang === "en"
                            ? enPhrase["lblMyProfile"]
                            : arPhrase["lblMyProfile"]}
                        </span>
                        <img
                          src="/assets/img/home/arrow--right.png"
                          alt=""
                          className={`${
                            lang === "en" ? "rotate-180" : ""
                          } w-[16px] h-[16px]`}
                        />
                      </Link>
                    </div>
                  ) : (
                    <div className="bg-[#fff] w-[327px] p-[20px] border-[1px] border-solid border-[#ebe0c7] m-auto rounded-[16px] ">
                      <div className="flex items-center justify-around mb-[9px]">
                        <h2 className="text-[16px] text-[#212121] font-Almarai font-bold   ">
                          {lang === "en"
                            ? enPhrase["lblWelcome"]
                            : arPhrase["lblWelcome"]}
                        </h2>
                        <img
                          src="/assets/img/home/hello.webp"
                          alt="Hello"
                          className="w-[24px] h-[24px]"
                        />
                      </div>
                      <p className="text-[12px] text-[#888888] font-Almarai text-center mb-[16px]">
                        {lang === "en"
                          ? enPhrase["lbllogin"]
                          : arPhrase["lbllogin"]}
                      </p>
                      <Link
                        to={""}
                        className={`flex gap-[8px] w-[287px] ${
                          lang === "en" ? "px-[43px]" : "px-[25px]"
                        } py-[8px] border-[1px] border-solid border-[#212121] m-auto rounded-[8px] `}
                        onClick={() => {
                          // window.open(getUri(), "_blank");
                          window.location.replace(getUri());
                        }}
                      >
                        <p className="text-[13px] text-[#333] font-Almarai font-[600]">
                          {lang === "en"
                            ? enPhrase["lbluaepass"]
                            : arPhrase["lbluaepass"]}
                        </p>
                        <img
                          src="/assets/img/UAEPASS_Logo.svg"
                          alt=""
                          className="w-[24px] h-[24px]"
                        />
                      </Link>
                    </div>
                  )}

                  <div className="w-full flex flex-col m-auto !mt-[24px] border-[1px] border-solid border-[#ebe0c7] bg-[#fff] pt-[34px] px-[24px] rounded-[24px] pb-[24px]">
                    <Link
                      to={"/"}
                      onClick={() => {
                        document.body.style.overflow = "unset";
                      }}
                      className="flex justify-between items-start mb-[32px]"
                    >
                      <div className="flex gap-[20px]">
                        <img
                          src="/assets/img/home/home.svg"
                          alt="HomeLogo"
                          className="w-[22px] h-[22px]"
                        />
                        <span className="text-[#212121] font-Almarai font-bold text-[18px]">
                          {lang === "en"
                            ? enPhrase["lblHome"]
                            : arPhrase["lblHome"]}
                        </span>
                      </div>
                      <img
                        src="/assets/img/home/arrow--right.svg"
                        alt=""
                        className={`${
                          lang === "en" ? "rotate-180" : ""
                        } w-[24px] h-[24px]`}
                      />
                    </Link>

                    {isAuthorized && (
                      <Link
                        to={"/request"}
                        onClick={() => {
                          document.body.style.overflow = "unset";
                        }}
                        className="flex justify-between items-start mb-[32px]"
                      >
                        <div className="flex gap-[20px]">
                          <img
                            src="/assets/img/home/document--multiple-01.svg"
                            alt="HomeLogo"
                            className="w-[22px] h-[22px]"
                          />
                          <span className="text-[#212121] font-Almarai font-bold text-[18px]">
                            {props.lang === "en" ? "Requests" : "الطلبات"}
                          </span>
                        </div>
                        <img
                          src="/assets/img/home/arrow--right.svg"
                          alt=""
                          className={`${
                            lang === "en" ? "rotate-180" : ""
                          } w-[24px] h-[24px]`}
                        />
                      </Link>
                    )}

                    <Link
                      to={isAuthorized ? "/case-overview" : "/login"}
                      onClick={() => {
                        document.body.style.overflow = "unset";
                      }}
                      className={`flex justify-between items-start mb-[32px] ${
                        pathname === "/case-overview" ? "text-[#B68A35]  " : ""
                      }`}
                    >
                      <div className="flex gap-[20px]">
                        <img
                          src="/assets/img/home/Dashboard.svg"
                          alt="dashboard-icon"
                        />
                        <span className="text-[#212121] font-Almarai font-bold text-[18px]">
                          {props.lang === "en"
                            ? props.enPhrase["lbl_caseTracking"] ??
                              "Case Tracking"
                            : props.arPhrase["lbl_caseTracking"] ??
                              " بوابة التنفيذ"}
                        </span>
                      </div>
                      <img
                        src="/assets/img/home/arrow--right.svg"
                        alt=""
                        className={`${
                          lang === "en" ? "rotate-180" : ""
                        } w-[24px] h-[24px]`}
                      />
                    </Link>

                    <div className="w-full h-[1px] bg-[#ebe0c7] m-auto mb-[30.5px]"></div>

                    <Link
                      to={"/"}
                      className="flex justify-between items-start mt-[32px]"
                    >
                      <div className="flex items-start gap-[20px]">
                        <img
                          src="/assets/img/icons8-earth-50.png"
                          alt="lan"
                          className="w-[24px] h-[24px]"
                        />
                        <span className="text-[#212121] font-Almarai font-bold text-[18px]">
                          {lang === "en"
                            ? enPhrase["lblLang"]
                            : arPhrase["lblLang"]}
                        </span>
                      </div>

                      <div className="w-[112px]">
                        <Link
                          to={pathname}
                          onClick={buttonClickHandler}
                          className={`${
                            lang === "en" ? "bg-[#2121211a]" : "bg-[#fff]"
                          } px-[8px] py-[5px] shadow-[0_2px_8px_0_rgba(0,0,0,0.12)]`}
                        >
                          <span
                            className={`text-[12px] ${
                              lang === "en"
                                ? "text-[#212121]"
                                : "text-[#b68a35]"
                            }  `}
                          >
                            العربية
                          </span>
                        </Link>

                        <Link
                          to={pathname}
                          onClick={buttonClickHandler}
                          className={`${
                            lang === "en" ? "bg-[#fff]" : "bg-[#2121211a]"
                          } px-[8px] py-[5px] shadow-[0_2px_8px_0_rgba(0,0,0,0.12)]`}
                        >
                          <span
                            className={`text-[12px] ${
                              lang === "en"
                                ? "text-[#b68a35]"
                                : "text-[#212121]"
                            } `}
                          >
                            English
                          </span>
                        </Link>
                      </div>
                    </Link>

                    <div className="flex gap-[20px] items-start mt-[32px]">
                      <img
                        src="/assets/img/home/callNow.svg"
                        alt="call"
                        className="w-[24px] h-[24px]"
                      />
                      <span className="text-[#212121] font-Almarai font-bold text-[18px]">
                        {lang === "en"
                          ? enPhrase["lblContactUs"]
                          : arPhrase["lblContactUs"]}
                      </span>
                    </div>

                    <div className="flex flex-col gap-[8px] mt-[20px]">
                      <div className="flex gap-[12px] bg-[#f9f7ed] rounded-[8px] pt-[8px] pb-[8px] h-[50px] ps-[12px] pe-[12px]">
                        <div className="flex">
                          <img src="/assets/img/home/phone.svg" alt="phone" />
                        </div>
                        <div className="flex justify-between w-full  items-center border-s-[1px] border-[#d7bc6d] ps-[16px]">
                          <p className="text-[11px] text-[#888] font-bold leading-none">
                            {lang === "en"
                              ? enPhrase["lblOurHotline"]
                              : arPhrase["lblOurHotline"]}
                          </p>
                          <Link
                            className="text-[16px] text-[#020202] font-bold"
                            to={`tel:${"600 55 33 55"}`}
                            dir="ltr"
                            rel="noreferrer"
                          >
                            {"600 55 33 55"}
                          </Link>
                        </div>
                      </div>

                      <div className="flex gap-[12px] bg-[#f9f7ed] rounded-[8px] pt-[8px] pb-[8px] h-[50px] ps-[12px] ">
                        <div className="flex">
                          <img src="/assets/img/home/email.svg" alt="email" />
                        </div>
                        <div className="flex justify-between w-full  items-center border-s-[1px] border-[#d7bc6d] ps-[16px] pe-[16px]">
                          <p className="text-[11px] text-[#888] font-bold leading-none">
                            {lang === "en"
                              ? enPhrase["lblEmail"]
                              : arPhrase["lblEmail"]}
                          </p>
                          <Link
                            className="text-[16px] text-[#020202] font-bold"
                            to={`mailto:${"info@eje.ae"}`}
                          >
                            {"info@eje.ae"}
                          </Link>
                        </div>
                      </div>

                      <div
                        className="flex gap-[12px] bg-[#f9f7ed] rounded-[8px] pt-[8px] pb-[8px] h-[50px] ps-[12px] items-center cursor-pointer mb-[18px]"
                        onClick={ChatHandler}
                      >
                        <div className="flex">
                          <img
                            src="/assets/img/home/callNowOpen.svg"
                            alt="callNow"
                          />
                        </div>
                        <div
                          className={`flex items-center justify-between w-full ${
                            lang === "en" ? "pr-[16px]" : "pl-[16px]"
                          }`}
                        >
                          <div className="flex items-center border-s-[1px] border-[#d7bc6d] ps-[16px] ">
                            <p className="text-[14px] text-[#815327] font-bold leading-none flex justify-between ">
                              {lang === "en"
                                ? enPhrase["lblChatToFares"]
                                : arPhrase["lblChatToFares"]}
                            </p>
                          </div>
                          <img
                            src="/assets/img/button/arrow.svg"
                            alt=""
                            className={` ${
                              lang === "en" ? "rotate-180" : ""
                            } w-[24px] h-[24px] `}
                          />
                        </div>
                      </div>

                      <div className="flex gap-[8px] m-auto ">
                        <div className="flex items-center justify-center w-[50px] h-[50px] bg-[#f9f7ed] rounded-[8px]">
                          <Link
                            to={`https://www.facebook.com/ejeuae`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="/assets/img/home/facebook.svg"
                              alt="facebook"
                            />
                          </Link>
                        </div>
                        <div className="flex items-center justify-center w-[50px] h-[50px] bg-[#f9f7ed] rounded-[8px]">
                          <Link
                            to={`https://twitter.com/ejeuae`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="/assets/img/home/twitter.svg"
                              alt="twitter"
                            />
                          </Link>
                        </div>
                        {/* <div className="flex items-center justify-center w-[50px] h-[50px] bg-[#f9f7ed] rounded-[8px]">
                          <Link
                            to={`https://www.linkedin.com/company/ejeuae/`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="/assets/img/home/linkedin.svg"
                              alt="linkedin"
                            />
                          </Link>
                        </div> */}
                        <div className="flex items-center justify-center w-[50px] h-[50px] bg-[#f9f7ed] rounded-[8px]">
                          <Link
                            to={`https://www.instagram.com/ejeuae/`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="/assets/img/home/instagram.svg"
                              alt="instagram"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="w-full h-[1px] bg-[#ebe0c7] mx-auto my-[24px]"></div>

                    <div className="m-auto object-contain w-[216px] h-[54px] flex items-center justify-center">
                      <img
                        src="/assets/img/logo-Ministry.svg"
                        alt=""
                        className="w-[168px] h-full"
                      />
                    </div>
                  </div>
                </div>
              </Menu>
            </div>

            <Link
              to="/"
              onClick={() => {
                document.body.style.overflow = "unset";
              }}
              className="flex gap-4 items-center justify-between border-s-[1px] ps-[10px] lg:ps-2 border-[#ebe0c7]  xl:border-none"
            >
              <div className="flex">
                <img
                  src={
                    window && window.innerWidth <= 768
                      ? "/assets/img/mobile/EJELogo.svg"
                      : "/assets/img/EJELogo.svg"
                  }
                  alt="logo"
                ></img>
              </div>
            </Link>
          </div>

          <div className="hidden md:block">
            {props.lang === "en" ? (
              <img
                src="/assets/img/logo-Ministry-EN.svg"
                alt="moj logo"
                className="w-[168px] h-[56px]"
              />
            ) : (
              <img src="/assets/img/logo-Ministry.svg" alt="moj logo" />
            )}
          </div>
          <div
            className="flex md:hidden gap-[8px]"
            onClick={() => {
              if (isAuthorized) {
                navigate("/profile");
                document.body.style.overflow = "unset";
              } else {
                navigate("/login");
                document.body.style.overflow = "unset";
              }
            }}
          >
            <img src="/assets/img/mobile/user.svg" alt="user" />
            {/* {isAuthorized ? "حسابي" : "دخول"} */}
            {isAuthorized ? (
              <p className="text-[#b68a35] text-[14px] font-Almarai font-bold">
                {props.lang === "en" ? "Account" : "حسابي"}
              </p>
            ) : (
              <p className="text-[#b68a35] text-[14px] font-Almarai font-bold">
                {props.lang === "en" ? "Sign In" : "دخول"}
              </p>
            )}
          </div>
        </div>
        <div className=" items-center justify-between w-full xl:py-0 hidden xl:flex min-h-[64px]">
          <div className="flex items-center  gap-[36px] xl:py-0">
            <Link
              to="/"
              onClick={() => {
                handleClickScroll("home");
              }}
              className={` font-Almarai font-bold items-center leading-[1.5rem] text-[14px] 2xl:text-base py-2 xl:py-3  hover:text-gold-100 ${
                pathname === "/"
                  ? " text-gold-100 border-b-[4px] border-[#b68a35]"
                  : " text-[#888]"
              }`}
            >
              <span className="flex gap-[12px]">
                {/* <FiHome size={22} /> */}
                <img
                  src="/assets/img/home.svg"
                  alt="HomeLogo"
                  className="w-[24px] h-[24px]"
                />
                {props.lang === "en" ? "Home" : "الرئيسية"}
              </span>
            </Link>

            {pathname === "/" || pathname === "/login" ? (
              <>
                <Link
                  to="/#services"
                  onClick={() => {
                    handleClickScroll("services");
                  }}
                  className={` inline-flex items-center leading-[1.5rem] text-[14px] 2xl:text-base py-2 xl:py-3 text-[#888] font-Almarai font-bold  ${
                    currentSection === "services" || activeClass === "services"
                      ? "transition-property duration-[0.5s] ease  border-solid border-[1px] border-[#b68a35] bg-[#b68a35] rounded-[16px] text-[#fff] pt-[7px] px-[12px] py-[7px]"
                      : " text-gold-1"
                  }`}

                  // className="border-solid border-[1px] border-[#b68a35] bg-[#b68a35] text-[#fff] rounded-[16px] pt-[7px] px-[12px] py-[7px] h-[32px] flex items-center justify-center text-[16px]"
                >
                  <span>{props.lang === "en" ? "Services" : "الخدمات"}</span>
                </Link>

                <Link
                  to="/#partner"
                  onClick={() => {
                    handleClickScroll("partner");
                  }}
                  className={` font-Almarai font-bold  inline-flex items-center leading-[1.5rem] text-[14px] 2xl:text-base py-2 xl:py-3   ${
                    currentSection === "partner" || activeClass === "partner"
                      ? "transition-property duration-[0.5s] ease border-solid border-[1px] border-[#b68a35] bg-[#b68a35] rounded-[16px] text-[#fff] pt-[7px] px-[12px] py-[7px]"
                      : " text-[#888]"
                  }`}
                >
                  <span>
                    {/* {props.lang === "en"
                      ? props.enPhrase["lbl_abouteje"]
                      : props.arPhrase["lbl_abouteje"]} */}
                    {props.lang === "en" ? "Partners" : "الشركاء"}
                  </span>
                </Link>

                <Link
                  to="/#news"
                  onClick={() => {
                    handleClickScroll("news");
                  }}
                  className={` font-Almarai font-bold inline-flex items-center leading-[1.5rem] text-[14px] 2xl:text-base py-2 xl:py-3   ${
                    currentSection === "news" || activeClass === "news"
                      ? "transition-property duration-[0.5s] ease border-solid border-[1px] border-[#b68a35] bg-[#b68a35] rounded-[16px] text-[#fff] pt-[7px] px-[12px] py-[7px]"
                      : " text-[#888]"
                  }`}
                >
                  <span>
                    {/* {props.lang === "en"
                      ? props.enPhrase["lbl_abouteje"]
                      : props.arPhrase["lbl_abouteje"]} */}
                    {props.lang === "en" ? "Media Center" : "المركز الإعلامي"}
                  </span>
                </Link>

                <Link
                  to="/#offices"
                  onClick={() => {
                    handleClickScroll("offices");
                  }}
                  className={`font-Almarai font-bold inline-flex items-center leading-[1.5rem] text-[14px] 2xl:text-base py-2 xl:py-3   ${
                    currentSection === "offices" || activeClass === "offices"
                      ? "transition-property duration-[0.5s] ease border-solid border-[1px] border-[#b68a35] bg-[#b68a35] rounded-[16px] text-[#fff] pt-[7px] px-[12px] py-[7px]"
                      : " text-[#888]"
                  }`}
                >
                  <span>{props.lang === "en" ? "Offices" : " المواقع"}</span>
                </Link>

                <Link
                  to="/#faqs"
                  onClick={() => {
                    handleClickScroll("faqs");
                  }}
                  className={`font-Almarai font-bold inline-flex items-center leading-[1.5rem] text-[14px] 2xl:text-base py-2 xl:py-3    ${
                    currentSection === "faqs" || activeClass === "faqs"
                      ? "transition-property duration-[0.5s] ease border-solid border-[1px] border-[#b68a35] bg-[#b68a35] rounded-[16px] text-[#fff] pt-[7px] px-[12px] py-[7px]"
                      : " text-[#888]"
                  }`}
                >
                  <span>{props.lang === "en" ? "Questions" : "الأسئلة"}</span>
                </Link>
              </>
            ) : null}

            {/* Lunch Home Page Commit */}
            <div className=" hidden flex-row w-[1px] items-center text-[#d1d1d1] lg:flex">
              |
            </div>
            <Link
              to={isAuthorized ? "/case-overview" : "/login"}
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              className={` inline-flex items-center gap-[12px] text-base py-2 xl:py-3  ${
                pathname === "/case-overview"
                  ? "text-[#B68A35] border-b-[4px] border-[#197bbd] "
                  : "text-gold-1"
              }`}

              // text-gold-100 underline underline-offset-8
            >
              <img
                src="/assets/img/home/CaseTrackingIcon.svg"
                alt="Track-Icon"
                className="w-[24px] h-[24px]"
              />
              <span className=" leading-[1.5rem] text-[14px] 2xl:text-base font-bold font-Almarai  text-[#197bbd]">
                {props.lang === "en"
                  ? props.enPhrase["lbl_caseTracking"] ?? "Case Tracking"
                  : props.arPhrase["lbl_caseTracking"] ?? " بوابة التنفيذ"}
              </span>
            </Link>
          </div>
          <div className="flex items-center justify-center  gap-[24px]  xl:py-0 relative">
            <button
              // onClick={() => {
              //   // if (props.hideUpload) {
              //   //   navigate("/");
              //   // }
              //   // handleClickScroll("contact");
              //   setopenContactPop(!openContactPop);
              // }}
              onClick={() => {
                setopenContactPop(!openContactPop);
              }}
              // onMouseLeave={() => {
              //   setopenContactPop(false);
              // }}

              // style={{
              //   height: "32px",
              //   display: "flex",
              //   justifyContent: "center",
              //   alignItems: "center",
              // }}
              // className="transition-all duration-300 px-4 font-semibold md:px-1 lg:px-4 xl:px-8 text-sm py-3 xl:py-3 bg-gold-100 text-white rounded focus:outline-none "
              className="font-Almarai font-bold transition-all duration-200 flex  items-center gap-[8px] text-base py-2 xl:py-3  "
            >
              <img
                src={
                  openContactPop || activeColor
                    ? "/assets/img/home/callNowOpen.svg"
                    : "/assets/img/home/callNow.svg"
                }
                alt=""
              />
              <p
                className={cx(
                  "text-[14px] font-Almarai font-bold",
                  openContactPop || activeColor
                    ? "text-[#b68a35]"
                    : "text-[#888]"
                )}
              >
                {props.lang === "en" ? "Contact US" : "اتصل بنا"}
              </p>
            </button>

            {openContactPop && (
              <ContactPop
                Lang={props.lang}
                setopenContactPop={setopenContactPop}
                arPhrase={props.arPhrase}
                enPhrase={props.enPhrase}
              />
            )}

            {/* {props.isUAEPass ? null : (
              <Link
                to={pathname}
                onClick={buttonClickHandler}
                className="transition-all duration-300 inline-flex items-center font-Almarai font-bold  text-[14px] py-2 xl:py-3 text-gold-100"
              >
                {props.lang === "en" && (
                  <img
                    className="mx-1 lg:mx-2 hidden lg:block"
                    src="/assets/img/uae/uae.webp"
                    srcSet="/assets/img/uae/uae@2x.webp 2x,
             /assets/img/uae/uae@3x.webp 3x"
                    alt="uae logo"
                  />
                )}
                {props.lang === "ar" && (
                  <img
                    className="mx-2 w-6 hidden lg:block"
                    src="/assets/img/uk.png"
                    alt="uae logo"
                  />
                )}
                <span>
                  {props.lang === "en"
                    ? props.arPhrase["lang"]
                    : props.enPhrase["lang"]}
                </span>
              </Link>
            )} */}
            {props.isUAEPass ? null : (
              <Link
                to={pathname}
                onClick={buttonClickHandler}
                className="transition-all duration-300 inline-flex items-center font-Almarai font-bold  text-[14px] py-2 xl:py-3 text-gold-100"
              >
                {props.lang === "en" && (
                  <img
                    className="mx-1 lg:mx-2 hidden lg:block"
                    src="/assets/img/uae/uae.webp"
                    srcSet="/assets/img/uae/uae@2x.webp 2x,
/assets/img/uae/uae@3x.webp 3x"
                    alt="uae logo"
                  />
                )}
                {props.lang === "ar" && (
                  <img
                    className="mx-2 w-6 hidden lg:block"
                    src="/assets/img/uk.png"
                    alt="uae logo"
                  />
                )}
                <span>
                  {props.lang === "en"
                    ? props.arPhrase["lang"]
                    : props.enPhrase["lang"]}
                </span>
              </Link>
            )}

            {/* Lunch Home Page Commit */}
            <div className=" items-center gap-2 lg:gap-4 hidden md:inline-flex  ">
              {!isAuthorized ? (
                <div className="py-[12px]">
                  <button
                    onClick={() => navigate("/login")}
                    className="flex flex-row-reverse gap-2 transition-all duration-300 px-3 font-semibold md:px-1 lg:px-4 xl:px-8 text-sm py-3 xl:py-3 bg-gold-100 text-white rounded-[8px] focus:outline-none h-[40px] items-center  "
                  >
                    <span>
                      {props.lang === "ar"
                        ? props.arPhrase["btnSignIn"]
                        : props.enPhrase["btnSignIn"]}
                    </span>
                    <img
                      src="/assets/img/login/LoginUser.svg"
                      alt="logout"
                      className="w-[24px] h-[24px]"
                    />
                  </button>
                </div>
              ) : (
                <div className="relative py-[12px]">
                  <div className="flex flex-row gap-[20px] items-center bg-[#F9F7ED] w-[138px] h-[40px] rounded-[8px] px-2">
                    <div className="flex flex-row items-center gap-[8px]">
                      <img
                        src="/assets/img/user.svg"
                        alt="logout"
                        className="w-6 h-6"
                      />
                      <div className="flex flex-col gap-1">
                        <span className="leading-none text-[14px] font-bold text-[#010202]">
                          {lang === "en"
                            ? enPhrase["lblMyAccount"]
                            : arPhrase["lblMyAccount"]}
                        </span>
                      </div>
                    </div>
                    <div
                      className="flex items-center justify-center w-10 h-10 rounded-2 cursor-pointer"
                      onClick={() => setOpenUserMenu(!openUserMenu)}
                    >
                      {openUserMenu ? (
                        <img
                          src="/assets/img/user-menu/arrow-up.svg"
                          alt="logout"
                          className="w-5 h-5"
                        />
                      ) : (
                        <img
                          src="/assets/img/user-menu/arrow-down.svg"
                          alt="logout"
                          className="w-5 h-5"
                        />
                      )}
                    </div>
                  </div>
                  {openUserMenu && (
                    <div className="flex flex-col bg-[#FFFFFF] border-1 border-[#EEE2D0] rounded-3 absolute top-16 left-0 w-[185px]">
                      <Link to="/profile">
                        <div
                          className="flex flex-row gap-3 items-center h-10 px-4 hover:bg-[#F9F7ED] cursor-pointer"
                          onClick={() => {
                            setOpenUserMenu(!openUserMenu);
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          <img
                            src="/assets/img/user-menu/user.svg"
                            alt="user"
                            className="w-5 h-5"
                          />
                          <span className="text-sm text-[#010202] hover:text-[#5D3B26]">
                            {lang === "en"
                              ? enPhrase["lblMyProfile"]
                              : arPhrase["lblMyProfile"]}
                          </span>
                        </div>
                      </Link>

                      <Link to="/archives">
                        <div
                          className="flex flex-row gap-3 items-center h-10 px-4 hover:bg-[#F9F7ED] cursor-pointer"
                          onClick={() => {
                            setOpenUserMenu(!openUserMenu);
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          <img
                            src="/assets/img/user-menu/archive.svg"
                            alt="archives"
                            className="w-5 h-5"
                          />
                          <span className="text-sm text-[#010202] hover:text-[#5D3B26]">
                            {lang === "en"
                              ? enPhrase["lblCaseArchive"]
                              : arPhrase["lblCaseArchive"]}
                          </span>
                        </div>
                      </Link>
                      <div
                        onClick={() => {
                          HappinessHandlerClick();
                          setOpenUserMenu(!openUserMenu);
                        }}
                        className="flex flex-row gap-3 items-center h-10 px-4 hover:bg-[#F9F7ED] cursor-pointer"
                      >
                        <img
                          src="/assets/img/user-menu/face-satisfied.svg"
                          alt="satisfied"
                          className="w-5 h-5"
                        />
                        <span className="text-sm text-[#010202] hover:text-[#5D3B26]">
                          {lang === "en"
                            ? enPhrase["lblHappiness"]
                            : arPhrase["lblHappiness"]}
                        </span>
                      </div>
                      <div
                        className="flex flex-row gap-3 items-center h-10 px-4 hover:bg-[#F9F7ED] cursor-pointer"
                        onClick={() => {
                          localStorage.removeItem("access_token");
                          sessionStorage.removeItem("access_token");
                          setOpenUserMenu(!openUserMenu);
                          // window.location.href =
                          //   "https://id.uaepass.ae/idshub/logout?redirect_uri=http:/eje.ae/login&ui_locales=" +
                          //   lang;
                          window.location.href =
                            "https://stg-id.uaepass.ae/idshub/logout?redirect_uri=https://eje.arabiansystems.com/login&ui_locales=" +
                            lang;
                        }}
                      >
                        <img
                          src="/assets/img/user-menu/logout.svg"
                          alt="logout"
                          className={`w-5 h-5 `}
                        />
                        <span className="text-sm text-[#010202] hover:text-[#5D3B26]">
                          {lang === "en"
                            ? enPhrase["lblSignout"]
                            : arPhrase["lblSignout"]}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {chatAdel === true ? (
          <LiveChatWidget
            chatSettings={chatSettings}
            license="16802304"
            visibility="minimized"
          />
        ) : (
          <LiveChatWidget
            chatSettings={chatSettings}
            license="16802304"
            visibility="maximized"
          />
        )}
      </Container>
    </nav>
  );
};
export default Nav;
